<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  created() {
    this.getNetworkIp();
  },
  methods: {
    getNetworkIp() {
      axios.get("/api/moudle/apisystem/ApiSoft/getIpFlag").then((res) => {
        if (res) {
          console.log("国内");
        } else {
          this.$router.push({ path: "/indexE" });
        }
      });
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: Source HanSansCN !important;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  li {
    cursor: pointer;
  }
}
button {
  cursor: pointer;
}
</style>
